import { UtmParameters } from '@videoblocks/events-ts/lib/storyblocks/navigation/PageViewEvent';

/**
 * "Urchin Tracking Module"
 * See https://en.wikipedia.org/wiki/UTM_parameters
 * Ported from StockBlocks, see CoreSites/Common/Framework/Tracking/CampaignTrackingFactory.php
 * Variable names are kept as close as possible to UtmParameters (@videoblocks/events-ts/lib/storyblocks/navigation/PageViewEvent)
 */

export type UtmValuesMap = {
    utm_adgroup: string | null,
    utm_campaign: string | null,
    campaign_date: number | null,
    utm_content: string | null,
    gcn: string | null,
    icn: string | null,
    language: string | null,
    mechanism: string | null,
    utm_medium: string | null,
    offer: string | null,
    rd: string | null,
    utm_source: string | null,
    utm_term: string | null,
    utm_v: number | null,
    vertical: string | null,
}

// Proper enum; key and value are identical
export enum UtmParam {
    utm_adgroup = 'utm_adgroup',
    utm_campaign = 'utm_campaign',
    campaign_date = 'campaign_date',
    utm_content = 'utm_content',
    gcn = 'gcn', // google campaign name
    icn = 'icn',
    language = 'language',
    mechanism = 'mechanism',
    utm_medium = 'utm_medium',
    offer = 'offer',
    rd = 'rd',
    utm_source = 'utm_source',
    utm_term = 'utm_term',
    utm_v = 'utm_v', // version
    vertical = 'vertical',
}

const parseAndSanitizeUtmParams = (parseFn: (paramName: UtmParam | string) => string | null): Partial<UtmValuesMap> => {
    // Gather all initial values
    const utmValuesMap: UtmValuesMap = {
        utm_adgroup: parseFn(UtmParam.utm_adgroup),
        utm_campaign: parseFn(UtmParam.utm_campaign),
        campaign_date: null,
        utm_content: parseFn(UtmParam.utm_content),
        gcn: parseFn(UtmParam.gcn),
        icn: parseFn(UtmParam.icn),
        language: parseFn(UtmParam.language),
        mechanism: parseFn(UtmParam.mechanism),
        utm_medium: parseFn(UtmParam.utm_medium),
        offer: parseFn(UtmParam.offer),
        rd: parseFn(UtmParam.rd),
        utm_source: parseFn(UtmParam.utm_source),
        utm_term: parseFn(UtmParam.utm_term),
        utm_v: null,
        vertical: parseFn(UtmParam.vertical)
    };

    // Sanitize numbers
    const campaignDate = parseFn(UtmParam.campaign_date);
    if (campaignDate) {
        utmValuesMap.campaign_date = Number.parseInt(campaignDate) ?? null;
    }
    const version = parseFn(UtmParam.utm_v);
    if (version) {
        utmValuesMap.utm_v = Number.parseInt(version) ?? null;
    }
    
    // If google params are present, override utm params
    if (!!utmValuesMap.gcn) {
        utmValuesMap.utm_campaign = utmValuesMap.gcn;
        utmValuesMap.utm_v = 2;
        utmValuesMap.utm_source = 'google';
        utmValuesMap.utm_medium = 'cpc';
    }

    return utmValuesMap;
}

const getUtmParamFromCookie = (cookies: Record<string, string>, paramName: UtmParam | string): string | null => {
    // Get standard cookie, or builder cookie (starts with b_)
    return cookies[paramName] ?? cookies[`b_${paramName}`] ?? null;
};

const getUtmParamFromSearchParams = (searchParams: URLSearchParams, paramName: string): string => {
    return searchParams.get(paramName) ?? '';
};

export const setFormValueForUtmParam = (form: any, paramName: UtmParam | string, paramValue: string | number | null): void => {
    try {
        const utmInput = form.querySelector(`input[name='${paramName}']`);
        utmInput.value = paramValue;
    } catch (_) {
        // Fail silently
    }
};

export function parseUtmParamsFromCookies(cookies: Record<string, string> | Array<Record<'name' | 'value', string>>): Partial<UtmValuesMap> {
    // next/headers returns cookies as Array<{ name: string, value: string }>, while 'react-cookies' returns an object
    const _cookies = Array.isArray(cookies)
        ? cookies.reduce((acc, cookie) => ({
            ...acc,
            [cookie.name]: cookie.value
        }), {})
        : cookies;

    const parseFromCookie = (paramName: UtmParam | string) => getUtmParamFromCookie(_cookies, paramName);
    return parseAndSanitizeUtmParams(parseFromCookie);
}

export function parseUtmParamsFromSearchParams(searchParams: URLSearchParams): Partial<UtmValuesMap> {
    const parseOneUtmParamFn = (paramName: UtmParam | string) => getUtmParamFromSearchParams(searchParams, paramName);
    return parseAndSanitizeUtmParams(parseOneUtmParamFn);
}

export function constructUtmParamsForPageViewEvent(sanitizedUtmValues: Partial<UtmValuesMap>): UtmParameters {
    return {
        campaign: sanitizedUtmValues.utm_campaign ?? '',
        google_campaign_name: sanitizedUtmValues.gcn ?? '',
        language: sanitizedUtmValues.language ?? '',
        content: sanitizedUtmValues.utm_content ?? '',
        mechanism: sanitizedUtmValues.mechanism ?? '',
        medium: sanitizedUtmValues.utm_medium ?? '',
        offer: sanitizedUtmValues.offer ?? '',
        source: sanitizedUtmValues.utm_source ?? '',
        vertical: sanitizedUtmValues.vertical ?? '',
        version: sanitizedUtmValues.utm_v,
        campaign_date: sanitizedUtmValues.campaign_date,
    }
}

// TODO: make our segmentation detection more robust. See Stockblocks/CoreSites/Common/Entities/ReferrerParser.php
export function isOrganicReferrer(searchParams: URLSearchParams): boolean {
    const sanitizedUtmValues = parseUtmParamsFromSearchParams(searchParams);
    return sanitizedUtmValues.gcn === '(organic)';
}
